import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SearchView() {
  const [searchTermOC, setSearchTermOC] = useState("");
  const [searchTermSolicitud, setSearchTermSolicitud] = useState("");
  const [errorOC, setErrorOC] = useState(false);
  const [errorSolicitud, setErrorSolicitud] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSearch = async () => {
    // Limpiar errores previos
    setErrorOC(false);
    setErrorSolicitud(false);

    let hasError = false;
    if (!searchTermOC) {
      setErrorOC(true);
      hasError = true;
    }
    if (!searchTermSolicitud) {
      setErrorSolicitud(true);
      hasError = true;
    }

    if (hasError) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.cnagro.cl/buscar-asignaciones",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            numeroOC: searchTermOC,
            numeroSolicitud: searchTermSolicitud,
          },
        }
      );
      if (response.data.error) {
        setErrorMessage(response.data.error);
      } else {
        navigate("/table", { state: { data: response.data } });
      }
    } catch (error) {
      console.error("Hubo un error al obtener las asignaciones:", error);
      if (error.response && error.response.status === 404) {
        toast.error("No se encontraron coincidencias.");
      } else {
        toast.error("Hubo un error al obtener las asignaciones.");
      }
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post("https://api.cnagro.cl/login", {
        username,
        password,
      });

      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        navigate("/fulltable");
      } else {
        toast.error("Usuario o contraseña incorrectos");
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      toast.error("Hubo un error al iniciar sesión");
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen">
      <ToastContainer />

      <div className="fixed top-0 left-0 w-full bg-white p-4 shadow-md flex justify-between items-center">
        <img
          src="/CNA_black.png"
          alt="Logo CNA"
          className="max-h-8 lg:max-h-20 md:max-h-12 sm:max-h-16"
        />

        <img
          src="/logoST2.png"
          alt="Logo ST2"
          className="hidden sm:block max-h-6 lg:max-h-20 md:max-h-10"
        />

        <div className="flex space-x-4 items-center">
          <div className="flex flex-col space-y-2">
            <input
              type="text"
              placeholder="Usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleLogin}
              style={{ backgroundColor: "rgb(128, 182, 26)" }}
              className="px-4 py-2 bg-red-500 text-white font-bold rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              Personal CNA
            </button>
          </div>
        </div>
      </div>

      <div className="flex-grow flex flex-col items-center justify-center space-y-4">
        <div className="bg-white bg-opacity-75 p-8 rounded-2xl shadow-md flex flex-col items-center justify-center space-y-4">
          <p className="font-bold text-neutral-600 text-center">
            Ingrese su orden de compra y número <br></br>
            de solicitud para ver la fecha estimada de despacho.
          </p>
          <div className="w-full relative">
            <input
              type="text"
              placeholder="Número de OC"
              value={searchTermOC}
              onChange={(e) => setSearchTermOC(e.target.value)}
              className={`p-2 border ${
                errorOC ? "border-red-500" : "border-gray-300"
              } rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-full`}
            />
            {errorOC && (
              <div className="tooltip">El número de OC es requerido</div>
            )}
          </div>
          <div className="w-full relative">
            <input
              type="text"
              placeholder="Número de Solicitud"
              value={searchTermSolicitud}
              onChange={(e) => setSearchTermSolicitud(e.target.value)}
              className={`p-2 border ${
                errorSolicitud ? "border-red-500" : "border-gray-300"
              } rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-full`}
            />
            {errorSolicitud && (
              <div className="tooltip">El número de solicitud es requerido</div>
            )}
          </div>
          <button
            onClick={handleSearch}
            className="px-4 py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            style={{ backgroundColor: "rgb(128, 182, 26)" }}
          >
            Buscar
          </button>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
}

export default SearchView;
